import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import MainPage from '../views/MainPage.vue'
import ThankYou from '../views/ThankYou.vue'
import NotFound from '../views/NotFound.vue'
import WelcomePage from '../views/WelcomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    meta: {
      title: "Linc Survey - V1",
      allowAnonymous: true,
    },
    name: "WelcomePage",
    component: WelcomePage,
  },
  {
    path: '/question/:type/:id',
    name: 'question',
    component: MainPage
  },
  {
    path: '/done',
    name: 'ThankYou',
    component: ThankYou
  },
  {
    path: '*',
    meta: {
      title: "Linc Middleware"
    },
    name: "PageNotFoundArea",
    component: NotFound
  },
  {
    path: '/page-not-found',
    meta: {
      title: "Linc Middleware",
      allowAnonymous: true
    },
    name: "PageNotFound",
    component: NotFound
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
