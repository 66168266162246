<template>
  <div>
    <div id="wrapper">
      <div class="container">
        <div class="row text-center">
          <div class="check_mark_img">
            <img
              :src="'../main_asset/Thank-You-1_files/checkmark.png'"
              alt="image_not_found"
            />
          </div>
          <div class="sub_title">
            <span>Your submission has been received,</span>
          </div>
          <div class="title pt-1">
            <h3>Thank you for giving the answer</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import styles1 from "../assets/thankYou/css2.css";
// import styles2 from "../assets/thankYou/style.css";
export default {
  name: "ThankYou",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  events: {},
  created: function () {},
};
</script>
<style scoped src="@/assets/thankYou/css2.css"></style>
<style scoped src="@/assets/thankYou/style.css"></style>
<!-- <style>
  @import url('../assets/thankYou/css2.module.css');
  @import url('../assets/thankYou/style.module.css');
</style> -->