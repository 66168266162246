const messages = {
    "en": {
        customerSurvey:"Customer Satisfaction Survey",
        name:"Name",
        departement:"Departement",
        email:"Email",
        companyName:"Company Name",
        language:"Language",
        position:"Job Position",
        cmlQuestion1:"Please rank 1 to 5 these following items in terms of importance (1 for the most important)",
        answerOption1:"EXCELLENT",
        answerOption2:"VERY GOOD",
        answerOption3:"GOOD",
        answerOption4:"FAIR",
        answerOption5:"POOR",
        cmlCustomerServiceHeader:"Customer Service",
        cmlCustomerServiceSub1:"Listen",
        cmlCustomerServiceSub2:"Respond",
        cmlCustomerServiceSub3:"Flexibility",
        cmlCustomerServiceSub4:"Accessibility",
        cmlOperationalHeader:"Operational",
        cmlOperationalSub1:"On time delivery",
        cmlOperationalSub2:"Delivery accuracy",
        cmlOperationalSub3:"Flexibility",
        cmlOperationalSub4:"Stock accuracy",
        cmlOperationalSub5:"Product handling",
        cmlOperationalSub6:"Storage availability",
        cmlCompetitivenessHeader:"Competitiveness",
        cmlCompetitivenessSub1:"Price",
        cmlCompetitivenessSub2:"Term of payment",
        cmlCompetitivenessSub3:"Respond",
        cmlTechnicalSupportHeader:"Technical Support",
        cmlTechnicalSupportSub1:"Product knowledge",
        cmlTechnicalSupportSub2:"Value Added Service",
        cmlTechnicalSupportSub3:"Customer complain",
        cmlComplianceHeader:"Compliance",
        cmlComplianceSub1:"HSE compliance",
        cmlComplianceSub2:"Legal compliance",
        cmlComplianceSub3:"Customer compliance",
        recomendation:"Would you recommend LINC Logistics to your colleagues?",
        recomendationBp:"Would you recommend LINC Express to your colleagues?",
        goodImpressionBp:"What good impression does LINC Express have?",
        goodImpression:"What good impression does LINC Logistics have?",
        correction:"Please kindly tell us what we could be doing better?",
        bpOperationalSub1:"Vehicle availability",
        bpOperationalSub2:"Delivery in full",
        bpOperationalSub3:"On time delivery",
        bpOperationalSub4:"POD / DO return",
        bpTechnicalSupportSub2:"Customer complain",
        bpTechnicalSupportSub3:"Accident responsive",
        bpComplianceSub1:"Vehicle safety equipment",
        bpComplianceSub2:"Vehicle customer compliance",
        bpComplianceSub3:"Driver obidience",
        bpComplianceSub4:"Legal compliance",
        nextQuestion:"Next Question",
        lastQuestion:"Previous Question",
        submitQuestion:"Submit",
        registrationTxt:"Customer Information",
        yes:"YES",
        no:"NO",
        cmlHelpQuestion_1:'Does our OM / Admin team serve you well?',
        cmlHelpQuestion_2:'Does our OM / Admin team understand and confirm their understanding of your complaint?',
        cmlHelpQuestion_3:'Does our OM / Admin team always respond politely and cooperatively?',
        cmlHelpQuestion_4:'Does our OM / Admin team respond promptly to both your requests and complaints?',
        cmlHelpQuestion_5:'Does our OM / Admin team process PO/DO documents quickly and effectively?',
        cmlHelpQuestion_6:'Can our OM / Admin team flexibly fulfill your requests (urgent cases)?',
        cmlHelpQuestion_7:'Is it easy for you to contact our OM / Admin team?',
        cmlHelpQuestion_8:'Can you reach our OM / Admin team at any time, even outside office hours?',
        cmlHelpQuestion_9:'Do shipments from our Warehouse arrive at the destination according to your scheduling?',
        cmlHelpQuestion_10:'Are shipments from our Warehouse always as per your request?',
        cmlHelpQuestion_11:'Have your goods ever been returned to the warehouse due to an error from the warehouse?',
        cmlHelpQuestion_12:'Does our Warehouse team unload/load goods quickly and effectively?',
        cmlHelpQuestion_13:'Can our Warehouse team flexibly handle your requests (urgent cases)?',
        cmlHelpQuestion_14:'Does our team always provide stock information (stock reports)?',
        cmlHelpQuestion_15:'Does our team always report items that are close to running out?',
        cmlHelpQuestion_16:'Does the stock take / stock count meet your expectations?',
        cmlHelpQuestion_17:'From receipt, storage, to release, are your goods handled according to your requirements?',
        cmlHelpQuestion_18:'Have you ever received complaints from your customers about goods that were contaminated, torn, damaged, or otherwise defective?',
        cmlHelpQuestion_19:'Does our team handle your defective/damaged goods well and quickly?',
        cmlHelpQuestion_20:'Are we always able to meet your storage needs?',
        cmlHelpQuestion_21:'Can we quickly provide storage according to your request?',
        cmlHelpQuestion_22:'Do you feel that the prices quoted by our company are competitive and fair compared to other competitors?',
        cmlHelpQuestion_23:'Does our Sales team prepare quotations and contracts, including changes, well?',
        cmlHelpQuestion_24:'Does the payment term provided by our company facilitate your business?',
        cmlHelpQuestion_25:'Does our Sales team maintain good communication with you?',
        cmlHelpQuestion_26:'Is it easy for you to access all information about our companys services?',
        cmlHelpQuestion_27:'Has our team ever failed to include documentation information about your goods for your customers, resulting in customer complaints?',
        cmlHelpQuestion_28:'Does our team sufficiently understand the type/characteristics of your goods stored in our warehouse?',
        cmlHelpQuestion_29:'Does our VAS service help your business?',
        cmlHelpQuestion_30:'Does our OM/Warehouse team respond well and quickly to your complaints?',
        cmlHelpQuestion_31:'When addressing technical issues, do you feel supported by the solutions provided by our team?',
        cmlHelpQuestion_32:'Is our team able to thoroughly and effectively resolve your technical issues, providing accurate evidence/reports?',
        cmlHelpQuestion_33:'Does our company meet all of your companys safety and quality requirements?',
        cmlHelpQuestion_34:'Are our warehouse facilities well-maintained and meet your companys safety standards?',
        cmlHelpQuestion_35:'Have you ever received complaints from your customers about improper stacking that does not meet your customers safety standards?',
        cmlHelpQuestion_36:'Does our company meet the legal requirements you need?',
        cmlHelpQuestion_37:'Is the handling of your goods in our warehouse in accordance with your companys requirements?',
        cmlHelpQuestion_38:'Do inspections of vehicles entering and exiting our warehouse area help ensure the safety of deliveries to your customers?',
        bpHelpQuestion_1:'Does our OM / Admin team serve you well?',
        bpHelpQuestion_2:'Does our OM / Admin team understand and confirm their understanding of your complaint?',
        bpHelpQuestion_3:'Does our OM / Admin team always respond politely and cooperatively?',
        bpHelpQuestion_4:'Does our OM / Admin team respond quickly to both your requests and complaints?',
        bpHelpQuestion_5:'Does our OM / Admin team process shipment documents quickly and effectively?',
        bpHelpQuestion_6:'Can our OM / Admin team flexibly accommodate your urgent requests?',
        bpHelpQuestion_7:'Is it easy for you to contact our OM / Admin team?',
        bpHelpQuestion_8:'Can you reach our OM / Admin team at any time, even outside office hours?',
        bpHelpQuestion_9:'Are we always able to provide a vehicle for your deliveries?',
        bpHelpQuestion_10:'Can we quickly supply a vehicle according to your request?',
        bpHelpQuestion_11:'Are we always able to meet your Delivery Orders?',
        bpHelpQuestion_12:'Is the quantity of our shipped goods always in accordance with your Delivery Order?',
        bpHelpQuestion_13:'Have your goods ever been returned due to errors by the transporter?',
        bpHelpQuestion_14:'Have you ever received complaints from your customers regarding contaminated, torn, damaged, or otherwise defective goods?',
        bpHelpQuestion_15:'Does our team handle your defective/damaged goods well and quickly?',
        bpHelpQuestion_16:'Does our delivery reach the destination according to your scheduling?',
        bpHelpQuestion_17:'Does our team report deliveries (POD/DO returns) quickly?',
        bpHelpQuestion_18:'Do you feel that the price quoted by our company is competitive and reasonable compared to other competitors?',
        bpHelpQuestion_19:'Does our Sales team prepare quotations and contracts, including any changes, well?',
        bpHelpQuestion_20:'Does the payment period offered by our company facilitate your business?',
        bpHelpQuestion_21:'Does our Sales team maintain good communication with you?',
        bpHelpQuestion_22:'Is it easy for you to access all information about our companys services?',
        bpHelpQuestion_23:'Has our team ever failed to include documentation information about your goods, leading to complaints from your customers?',
        bpHelpQuestion_24:'Is the handling of your goods in our warehouse in accordance with your companys requirements?',
        bpHelpQuestion_25:'Does our team understand the type/characteristics of your goods sent using our services?',
        bpHelpQuestion_26:'Does our OM/Operations team respond to your complaints well and quickly?',
        bpHelpQuestion_27:'When addressing technical issues, do you feel helped by the solutions provided by our team?',
        bpHelpQuestion_28:'Is our team able to resolve your technical issues thoroughly and well, accompanied by accurate evidence/reports?',
        bpHelpQuestion_29:'Does our team respond quickly to accidents or disruptions on the way?',
        bpHelpQuestion_30:'Does our company meet all the safety and quality requirements of your company?',
        bpHelpQuestion_31:'Do our vehicles and vehicle safety equipment meet your companys safety standards?',
        bpHelpQuestion_32:'Have you ever received complaints from your customers about cargo stacking that does not meet your customers safety standards?',
        bpHelpQuestion_33:'Do our vehicle inspections help ensure the safety of deliveries to your customers?',
        bpHelpQuestion_34:'Are our vehicles well-maintained and meet your companys requirements?',
        bpHelpQuestion_35:'Do our Drivers or Helpers comply with rules and regulations at your location?',
        bpHelpQuestion_36:'Are our Drivers or Helpers neatly dressed and wearing Personal Protective Equipment (PPE) at your location?',
        bpHelpQuestion_37:'Do our Drivers drive safely and prioritize road safety at your location?',
        bpHelpQuestion_38:'Does our company meet the legal requirements you need?',
        bpHelpQuestion_39:'Do our vehicles meet legal vehicle requirements according to your standards?',
        
    },
    "id": {
        
        bpHelpQuestion_1:'Apakah tim OM / Admin kami melayani Bapak/Ibu dengan baik?',
        bpHelpQuestion_2:'Apakah tim OM / Admin kami mengerti dan mengkonfirmasi pemahamannya tentang keluhan Bapak/Ibu?',
        bpHelpQuestion_3:'Apakah tim OM / Admin kami selalu menanggapi dengan ramah dan kooperatif?',
        bpHelpQuestion_4:'Apakah tim OM / Admin kami selalu menanggapi, baik permintaan maupun keluhan Bapak/Ibu dengan cepat?',
        bpHelpQuestion_5:'Apakah tim OM / Admin kami memproses dokumen pengiriman dengan cepat dan efektif?',
        bpHelpQuestion_6:'Apakah tim OM / Admin kami dapat secara fleksibel memenuhi permintaan Bapak/Ibu (urgent case)?',
        bpHelpQuestion_7:'Apakah Bapak/Ibu mudah menghubungi tim OM / Admin kami?',
        bpHelpQuestion_8:'Apakah Bapak/Ibu dapat menghubungi tim OM / Admin kami setiap saat, meskipun di luar jam kantor?',
        bpHelpQuestion_9:'Apakah kami selalu dapat memenuhi permintaan kendaraan untuk pengiriman Bapak/Ibu?',
        bpHelpQuestion_10:'Apakah kami dapat dengan cepat menyediakan kendaraan sesuai permintaan Bapak/Ibu?',
        bpHelpQuestion_11:'Apakah kami selalu dapat memenuhi DO Bapak/Ibu?',
        bpHelpQuestion_12:'Apakah quantity barang pengiriman kami selalu sesuai dengan DO Bapak/Ibu?',
        bpHelpQuestion_13:'Apakah barang Bapak/Ibu pernah dikembalikan/retur dengan isu kesalahan dari transporter?',
        bpHelpQuestion_14:'Apakah Bapak/Ibu pernah mendapat keluhan dari pelanggan Bapak/Ibu terkait barang yang terkontaminasi, sobek, rusak, atau lainnya?',
        bpHelpQuestion_15:'Apakah tim kami menangani barang defect/damage Bapak/Ibu dengan baik dan cepat?',
        bpHelpQuestion_16:'Apakah pengiriman kami sampai ke tujuan sesuai dengan penjadwalan Bapak/Ibu?',
        bpHelpQuestion_17:'Apakah tim kami melaporkan pengiriman (POD/DO return) dengan cepat?',
        bpHelpQuestion_18:'Apakah Bapak/Ibu merasa harga penawaran yang diberikan oleh perusahaan kami cukup kompetitif dan wajar dibandingkan dengan kompetitor lain?',
        bpHelpQuestion_19:'Apakah tim Sales kami menyiapkan penawaran dan kontrak, serta perubahannya dengan baik?',
        bpHelpQuestion_20:'Apakah jangka waktu pembayaran yang perusahaan kami berikan memudahkan bisnis Bapak/Ibu?',
        bpHelpQuestion_21:'Apakah tim Sales kami menjalin komunikasi dengan baik?',
        bpHelpQuestion_22:'Apakah Bapak/Ibu merasa mudah untuk mengakses semua informasi tentang jasa perusahaan kami?',
        bpHelpQuestion_23:'Apakah tim kami pernah tidak menyertakan dokumentasi informasi tentang barang Bapak/Ibu kepada pelanggan Bapak/Ibu, hingga menimbulkan keluhan dari pelanggan?',
        bpHelpQuestion_24:'Apakah penanganan barang Bapak/Ibu di gudang kami sesuai dengan persyaratan perusahaan Bapak/Ibu?',
        bpHelpQuestion_25:'Apakah tim kami cukup memahami jenis/karakteristik barang Bapak/Ibu yang dikirim menggunakan jasa kami?',
        bpHelpQuestion_26:'Apakah tim OM/Operasional kami menanggapi keluhan Bapak/Ibu dengan baik dan cepat?',
        bpHelpQuestion_27:'Dalam mengatasi permasalahan teknis, apakah Bapak/Ibu merasa terbantu dengan penyelesaian yang dilakukan oleh tim kami?',
        bpHelpQuestion_28:'Apakah tim kami mampu menyelesaikan masalah teknis Bapak/Ibu dengan tuntas dan baik disertai dengan bukti/laporan yang akurat?',
        bpHelpQuestion_29:'Apakah tim kami menanggapi kejadian kecelakaan atau gangguan di perjalanan dengan cepat?',
        bpHelpQuestion_30:'Apakah perusahaan kami memenuhi seluruh persyaratan safety dan quality dari perusahaan Bapak/Ibu?',
        bpHelpQuestion_31:'Apakah kendaraan dan kelengkapan safety kendaraan kami sesuai dengan standar safety perusahaan Bapak/Ibu?',
        bpHelpQuestion_32:'Apakah Bapak/Ibu pernah mendapat keluhan dari pelanggan Bapak/Ibu terkait penumpukan barang yang tidak sesuai standar safety perusahaan pelanggan Bapak/Ibu?',
        bpHelpQuestion_33:'Apakah pemeriksaan kendaraan kami membantu menjaga keselamatan pengiriman ke pelanggan Bapak/Ibu?',
        bpHelpQuestion_34:'Apakah kendaraan kami terpelihara dengan baik dan memenuhi persyaratan perusahaan Bapak/Ibu?',
        bpHelpQuestion_35:'Apakah Pengemudi atau Helper kami mematuhi peraturan dan tata tertib yang berlaku di lokasi Bapak/Ibu?',
        bpHelpQuestion_36:'Apakah Pengemudi atau Helper kami berpakaian dengan rapi dan menggunakan Alat Pelindung Diri (APD) di lokasi Bapak/Ibu?',
        bpHelpQuestion_37:'Apakah Pengemudi mengemudikan kendaraan dengan aman dan selalu mengutamakan keselamatan berkendara di lokasi Bapak/Ibu?',
        bpHelpQuestion_38:'Apakah perusahaan kami memenuhi persyaratan legalitas yang Bapak/Ibu butuhkan?',
        bpHelpQuestion_39:'Apakah kendaraan kami memenuhi persyaratan legalitas kendaraan sesuai dengan persyaratan Bapak/Ibu?',

        cmlHelpQuestion_1:'Apakah tim OM / Admin kami melayani Bapak/Ibu dengan baik?',
        cmlHelpQuestion_2:'Apakah tim OM / Admin kami mengerti dan mengkonfirmasi pemahamannya tentang keluhan Bapak/Ibu?',
        cmlHelpQuestion_3:'Apakah tim OM / Admin kami selalu menanggapi dengan ramah dan kooperatif?',
        cmlHelpQuestion_4:'Apakah tim OM / Admin kami selalu menanggapi, baik permintaan maupun keluhan Bapak/Ibu dengan cepat?',
        cmlHelpQuestion_5:'Apakah tim OM / Admin kami memproses dokumen PO/DO dengan cepat dan efektif?',
        cmlHelpQuestion_6:'Apakah tim OM / Admin kami dapat secara fleksibel memenuhi permintaan Bapak/Ibu (urgent case)?',
        cmlHelpQuestion_7:'Apakah Bapak/Ibu mudah menghubungi tim OM / Admin kami?',
        cmlHelpQuestion_8:'Apakah Bapak/Ibu dapat menghubungi tim OM / Admin kami setiap saat, meskipun di luar jam kantor?',
        cmlHelpQuestion_9:'Apakah pengiriman dari Gudang kami sampai ke tujuan sesuai dengan penjadwalan Bapak/Ibu?',
        cmlHelpQuestion_10:'Apakah pengiriman dari Gudang kami selalu sesuai dengan permintaan Bapak/Ibu?',
        cmlHelpQuestion_11:'Apakah barang Bapak/Ibu pernah dikembalikan/retur ke gudang dengan isu kesalahan dari gudang?',
        cmlHelpQuestion_12:'Apakah tim Gudang kami memproses bongkar/muat dengan cepat dan efektif?',
        cmlHelpQuestion_13:'Apakah tim Gudang kami dapat secara fleksibel menjalankan permintaan Bapak/Ibu, (urgent case)?',
        cmlHelpQuestion_14:'Apakah tim kami selalu menginformasikan stock barang (laporan stock)?',
        cmlHelpQuestion_15:'Apakah tim kami selalu melaporkan untuk barang-barang yang mendekati habis?',
        cmlHelpQuestion_16:'Apakah hasil stock take  / stock opname memenuhi ekspetasi Bapak/Ibu?',
        cmlHelpQuestion_17:'Mulai dari penerimaan, penyimpanan, hingga pengeluaran, apakah barang Bapak/Ibu kami tangani sesuai dengan persyaratan Bapak/Ibu?',
        cmlHelpQuestion_18:'Apakah Bapak/Ibu pernah mendapat keluhan dari pelanggan Bapak/Ibu terkait barang yang terkontaminasi, sobek, rusak, atau lainnya?',
        cmlHelpQuestion_19:'Apakah tim kami menangani barang defect/damage Bapak/Ibu dengan baik dan cepat?',
        cmlHelpQuestion_20:'Apakah kami selalu dapat memenuhi permintaan storage Bapak/Ibu?',
        cmlHelpQuestion_21:'Apakah kami dapat dengan cepat menyediakan storage sesuai permintaan Bapak/Ibu?',
        cmlHelpQuestion_22:'Apakah Bapak/Ibu merasa harga penawaran yang diberikan oleh perusahaan kami cukup kompetitif dan wajar dibandingkan dengan kompetitor lain?',
        cmlHelpQuestion_23:'Apakah tim Sales kami menyiapkan penawaran dan kontrak, serta perubahannya dengan baik?',
        cmlHelpQuestion_24:'Apakah jangka waktu pembayaran yang perusahaan kami berikan memudahkan bisnis Bapak/Ibu?',
        cmlHelpQuestion_25:'Apakah tim Sales kami menjalin komunikasi dengan baik?',
        cmlHelpQuestion_26:'Apakah Bapak/Ibu merasa mudah untuk mengakses semua informasi tentang jasa perusahaan kami?',
        cmlHelpQuestion_27:'Apakah tim kami pernah tidak menyertakan dokumentasi informasi tentang barang Bapak/Ibu kepada pelanggan Bapak/Ibu, hingga menimbulkan keluhan dari pelanggan?',
        cmlHelpQuestion_28:'Apakah tim kami cukup memahami jenis/karakteristik barang Bapak/Ibu yang disimpan di gudang kami?',
        cmlHelpQuestion_29:'Apakah jasa VAS perusahaan kami membantu bisnis Bapak/Ibu?',
        cmlHelpQuestion_30:'Apakah tim OM/Gudang kami menanggapi keluhan Bapak/Ibu dengan baik dan cepat?',
        cmlHelpQuestion_31:'Dalam mengatasi permasalahan teknis, apakah Bapak/Ibu merasa terbantu dengan penyelesaian yang dilakukan oleh tim kami?',
        cmlHelpQuestion_32:'Apakah tim kami mampu menyelesaikan masalah teknis Bapak/Ibu dengan tuntas dan baik disertai dengan bukti/laporan yang akurat?',
        cmlHelpQuestion_33:'Apakah perusahaan kami memenuhi seluruh persyaratan safety dan quality dari perusahaan Bapak/Ibu?',
        cmlHelpQuestion_34:'Apakah fasilitas gudang kami terpelihara dengan baik dan sesuai dengan standar safety perusahaan Bapak/Ibu?',
        cmlHelpQuestion_35:'Apakah Bapak/Ibu pernah mendapat keluhan dari pelanggan Bapak/Ibu terkait penumpukan barang yang tidak sesuai standar safety perusahaan pelanggan Bapak/Ibu?',
        cmlHelpQuestion_36:'Apakah perusahaan kami memenuhi persyaratan legalitas yang Bapak/Ibu butuhkan?',
        cmlHelpQuestion_37:'Apakah penanganan barang Bapak/Ibu di gudang kami sesuai dengan persyaratan perusahaan Bapak/Ibu?',
        cmlHelpQuestion_38:'Apakah pemeriksaan kendaraan yang masuk keluar di area gudang kami membantu menjaga keselamatan pengiriman ke pelanggan Bapak/Ibu?',
        customerSurvey:"SURVEI KEPUASAN PELANGGAN",
        yes:"YA",
        no:"TIDAK",
        registrationTxt:"Informasi Customer",
        name:"Nama",
        departement:"Departement",
        email:"Email",
        companyName:"Nama Perusahaan",
        language:"Bahasa",
        position:"Jabatan",
        cmlQuestion1:"Beri peringkat 1 hingga 5 untuk item-item berikut berdasarkan tingkat kepentingannya (1 untuk yang paling penting)",
        answerOption1:"SANGAT BAIK",
        answerOption2:"BAIK SEKALI",
        answerOption3:"BAIK",
        answerOption4:"CUKUP",
        answerOption5:"BURUK",
        cmlCustomerServiceHeader:"Layanan Pelanggan",
        cmlCustomerServiceSub1:"Mendengarkan",
        cmlCustomerServiceSub2:"Menanggapi",
        cmlCustomerServiceSub3:"Fleksibilitas",
        cmlCustomerServiceSub4:"Aksesibilitas",
        cmlOperationalHeader:"Operasional",            
        cmlOperationalSub1:"Pengiriman tepat waktu",
        cmlOperationalSub2:"Akurasi pengiriman",
        cmlOperationalSub3:"Fleksibilitas",
        cmlOperationalSub4:"Akurasi stok",
        cmlOperationalSub5:"Penanganan produk",
        cmlOperationalSub6:"Ketersediaan penyimpanan",
        cmlCompetitivenessHeader:"Daya Saing",
        cmlCompetitivenessSub1:"Harga",
        cmlCompetitivenessSub2:"Jangka waktu pembayaran",
        cmlCompetitivenessSub3:"Tanggapan",
        cmlTechnicalSupportHeader:"Dukungan Teknis",
        cmlTechnicalSupportSub1:"Pengetahuan produk",
        cmlTechnicalSupportSub2:"Layanan Nilai Tambah",
        cmlTechnicalSupportSub3:"Keluhan pelanggan",
        cmlComplianceHeader:"Kepatuhan",
        cmlComplianceSub1:"Kepatuhan K3LH",
        cmlComplianceSub2:"Kepatuhan hukum",
        cmlComplianceSub3:"Kepatuhan pelanggan",
        recomendation:"Apakah Anda akan merekomendasikan LINC Logistics kepada kolega Anda?",
        recomendationBp:"Apakah Anda akan merekomendasikan LINC Express kepada kolega Anda?",
        goodImpression:"Kesan baik apa yang dimiliki oleh LINC Logistics?",
        goodImpressionBp:"Kesan baik apa yang dimiliki oleh LINC Express?",
        correction:"Mohon beritahu kami apa yang bisa kami lakukan lebih baik lagi?",
        bpOperationalSub1:"Ketersediaan kendaraan",
        bpOperationalSub2:"Pengiriman secara lengkap",
        bpOperationalSub3:"Pengiriman tepat waktu",
        bpOperationalSub4:"Pengembalian POD / DO",
        bpTechnicalSupportSub2:"Keluhan pelanggan",
        bpTechnicalSupportSub3:"Responsif terhadap kecelakaan",
        bpComplianceSub1:"Perlengkapan keselamatan kendaraan",
        bpComplianceSub2:"Kepatuhan pelanggan terhadap kendaraan",
        bpComplianceSub3:"Ketaatan pengemudi",
        bpComplianceSub4:"Kepatuhan hukum",
        nextQuestion:"Pertanyaan Selanjutnya",
        lastQuestion:"Pertanyaan Sebelumnya",
        submitQuestion:"Kirim",

        

    }
};

export default messages;