<template>
  <div>
    <div class="form_content">
      <div class="row">
        <div class="col-lg-4">

          <div v-show="questionData?.field != 'register'" class="text-white form_title ps-5">
            <h1 v-html="questionData?.title"></h1>
              <div class="popover-container" v-show="questionData?.isPopOver" ref="popoverContainer">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="togglePopover"
                  @blur="hidePopover"
                >
                <svg  class="help-icon" version="1.1" viewBox="0 0 91.999 92"  xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M45.385,0.004C19.982,0.344-0.334,21.215,0.004,46.619c0.34,25.393,21.209,45.715,46.611,45.377  c25.398-0.342,45.718-21.213,45.38-46.615C91.655,19.986,70.785-0.335,45.385,0.004z M45.249,74l-0.254-0.004  c-3.912-0.116-6.67-2.998-6.559-6.852c0.109-3.788,2.934-6.538,6.717-6.538l0.227,0.004c4.021,0.119,6.748,2.972,6.635,6.937  C51.903,71.346,49.122,74,45.249,74z M61.704,41.341c-0.92,1.307-2.943,2.93-5.492,4.916l-2.807,1.938  c-1.541,1.198-2.471,2.325-2.82,3.434c-0.275,0.873-0.41,1.104-0.434,2.88l-0.004,0.451H39.429l0.031-0.907  c0.131-3.728,0.223-5.921,1.768-7.733c2.424-2.846,7.771-6.289,7.998-6.435c0.766-0.577,1.412-1.234,1.893-1.936  c1.125-1.551,1.623-2.772,1.623-3.972c0-1.665-0.494-3.205-1.471-4.576c-0.939-1.323-2.723-1.993-5.303-1.993  c-2.559,0-4.311,0.812-5.359,2.478c-1.078,1.713-1.623,3.512-1.623,5.35v0.457H27.935l0.02-0.477  c0.285-6.769,2.701-11.643,7.178-14.487C37.946,18.918,41.446,18,45.53,18c5.346,0,9.859,1.299,13.412,3.861  c3.6,2.596,5.426,6.484,5.426,11.556C64.368,36.254,63.472,38.919,61.704,41.341z"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                </button>
                <div
                  v-show="isPopoverVisible"
                  class="popover-content"
                  ref="popoverContent"
                  :style="popoverPosition"
                  v-html="questionData?.popOverMessage"
                ></div>
              </div>
          </div> 
        </div>
        <div class="col-lg-4 text-center">
          <div class="form_img">
            <img v-show="questionData?.src" v-bind:src="questionData?.src" alt="image_not_found" :class="[questionData?.border == false ? '' : 'bordered' ]" />
          </div>
        </div>
        <div class="col-lg-4">
          <span v-if="questionData?.type == 'register'">
            <h1 class="register-title" v-html="questionData?.title"></h1>
            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <label
                    for="step1Email"
                    class="col-sm-5 col-form-label label-pointer rounded-circle text-center"
                    style="
                      text-align: left !important;
                    "
                    >{{ $t("language") }}</label
                  >
                  <div class="col-sm-7">
                    <select
                      @change="langChanged($i18n.locale)"
                      class="form-select"
                      v-model="selectedLang"
                    >
                      <option
                        v-for="(lang, i) in langs"
                        :key="`Lang${i}`"
                        :value="lang.value"
                      >
                        <font style="color: #212529">{{ lang.name }}</font>
                      </option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <label
                    for="step1Name"
                    class="col-sm-5 col-form-label label-pointer rounded-circle text-center "
                    style="
                      text-align: left !important;
                    "
                    >{{ $t("name") }}</label
                  >
                  <div class="col-sm-7">
                    <input
                      type="text"
                      style="opacity: 1 !important"
                      readonly
                      class="form-control"
                      id="step1Name"
                      v-model="forms.name"
                      v-on:keydown.enter.prevent
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <label
                    for="staticEmail"
                    class="col-sm-5 col-form-label label-pointer rounded-circle text-center "
                    style="
                      text-align: left !important;
                    "
                    >{{ $t("position") }}</label
                  >
                  <div class="col-sm-7">
                    <input
                      type="text"
                      style="opacity: 1 !important"
                      required
                      class="form-control"
                      id="staticEmail"
                      v-model="forms.departement"
                      v-on:keydown.enter.prevent
                    />
                    <label
                      v-show="requiredError"
                      :id="this.pageList + 'stp_4_select_option-error'"
                      class="error"
                      for="stp_4_select_option"
                      >This field is required.</label
                    >
                  </div>
                </div>
              </li>
            </ul>
            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <label
                    for="step1Email"
                    class="col-sm-5 col-form-label label-pointer rounded-circle text-center "
                    style="
                      text-align: left !important;
                    "
                    >{{ $t("email") }}</label
                  >
                  <div class="col-sm-7">
                    <input
                      type="email"
                      style="opacity: 1 !important"
                      readonly
                      class="form-control"
                      id="step1Email"
                      v-model="forms.email"
                      v-on:keydown.enter.prevent
                    />
                  </div>
                </div>
              </li>
            </ul>
            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <label
                    for="step1Email"
                    class="col-sm-5 col-form-label label-pointer rounded-circle text-center "
                    style="
                      text-align: left !important;
                    "
                    >{{ $t("companyName") }}</label
                  >
                  <div class="col-sm-7">
                    <input
                      type="email"
                      style="opacity: 1 !important"
                      readonly
                      class="form-control"
                      id="step1Email"
                      v-model="forms.company"
                      v-on:keydown.enter.prevent
                    />
                  </div>
                </div>
              </li>
            </ul>
          </span>
          <span v-else-if="questionData?.type == 'q1'">
            <label
              v-show="requiredError"
              :id="this.pageList + 'stp_4_select_option-error'"
              class="error"
              for="stp_4_select_option"
              >All Fields are Required.</label
            >
            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <div
                    class="col-sm-5"
                    style="
                      align-items: center;
                      text-align: center !important;
                      display: inline-block;
                    "
                  >
                    <select
                      class="form-control"
                      v-model="forms.question_1"
                      @change="changeSelect($event, 0)"
                    >
                      <option selected value="">-- Please Select --</option>
                      <option
                        v-for="o in options"
                        :value="o.name"
                        :key="o.name"
                        :disabled="o.disabled"
                      >
                        {{ o.name }}
                      </option>
                    </select>
                  </div>

                  <label
                    for="step1Name"
                    class="col-sm-7 col-form-label label-pointer rounded-circle text-center"
                    style="
                      text-align: left !important;
                    "
                    >{{$t('cmlCustomerServiceHeader')}}</label
                  >
                </div>
              </li>
            </ul>

            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <div
                    class="col-sm-5"
                    style="
                      align-items: center;
                      text-align: center !important;
                      display: inline-block;
                    "
                  >
                    <select
                      class="form-control"
                      v-model="forms.question_2"
                      @change="changeSelect($event, 1)"
                    >
                      <option selected value="">-- Please Select --</option>
                      <option
                        v-for="o in options"
                        :value="o.name"
                        :key="o.name"
                        :disabled="o.disabled"
                      >
                        {{ o.name }}
                      </option>
                    </select>
                  </div>

                  <label
                    for="step1Name"
                    class="col-sm-7 col-form-label label-pointer rounded-circle text-center"
                    style="
                      text-align: left !important;
                    "
                    >{{$t('cmlOperationalHeader')}}</label
                  >
                </div>
              </li>
            </ul>

            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <div
                    class="col-sm-5"
                    style="
                      align-items: center;
                      text-align: center !important;
                      display: inline-block;
                    "
                  >
                    <select
                      class="form-control"
                      v-model="forms.question_3"
                      @change="changeSelect($event, 2)"
                    >
                      <option selected value="">-- Please Select --</option>
                      <!-- <option v-for="n in 5" :key="n" :value="n">
                        {{ n }}
                      </option> -->
                      <option
                        v-for="o in options"
                        :value="o.name"
                        :key="o.name"
                        :disabled="o.disabled"
                      >
                        {{ o.name }}
                      </option>
                    </select>
                  </div>

                  <label
                    for="step1Name"
                    class="col-sm-7 col-form-label label-pointer rounded-circle text-center"
                    style="
                      text-align: left !important;
                    "
                    >{{$t('cmlCompetitivenessHeader')}}</label
                  >
                </div>
              </li>
            </ul>

            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <div
                    class="col-sm-5"
                    style="
                      align-items: center;
                      text-align: center !important;
                      display: inline-block;
                    "
                  >
                    <select
                      class="form-control"
                      v-model="forms.question_4"
                      @change="changeSelect($event, 3)"
                    >
                      <option selected value="">-- Please Select --</option>
                      <!-- <option v-for="n in 5" :key="n" :value="n">
                        {{ n }}
                      </option> -->
                      <option
                        v-for="o in options"
                        :value="o.name"
                        :key="o.name"
                        :disabled="o.disabled"
                      >
                        {{ o.name }}
                      </option>
                    </select>
                  </div>

                  <label
                    for="step1Name"
                    class="col-sm-7 col-form-label label-pointer rounded-circle text-center"
                    style="
                      text-align: left !important;
                    "
                    >{{$t('cmlTechnicalSupportHeader')}}</label
                  >
                </div>
              </li>
            </ul>

            <ul class="text-uppercase list-unstyled">
              <li>
                <div
                  class="form-group row step_2 rounded-pill animate__animated animate__fadeInRight animate_25ms"
                >
                  <div
                    class="col-sm-5"
                    style="
                      align-items: center;
                      text-align: center !important;
                      display: inline-block;
                    "
                  >
                    <select
                      class="form-control"
                      v-model="forms.question_5"
                      @change="changeSelect($event, 4)"
                    >
                      <option selected value="">-- Please Select --</option>
                      <!-- <option v-for="n in 5" :key="n" :value="n">
                        {{ n }}
                      </option> -->
                      <option
                        v-for="o in options"
                        :value="o.name"
                        :key="o.name"
                        :disabled="o.disabled"
                      >
                        {{ o.name }}
                      </option>
                    </select>
                  </div>

                  <label
                    for="step1Name"
                    class="col-sm-7 col-form-label label-pointer rounded-circle text-center"
                    style="
                      text-align: left !important;
                    "
                    >{{$t('cmlComplianceHeader')}}</label
                  >
                </div>
              </li>
            </ul>
          </span>
          <span v-else-if="questionData?.type == 'q2'">
            <div class="form_items radio-list">
              <ul class="text-uppercase list-unstyled">
                <li>
                  <label
                    :for="this.pageList + 'opt_1'"
                    class="rounded animate__animated animate__fadeInRight animate_25ms"
                    :class="[forms[questionData.field] == 5 ? 'active selectedRadio' : '']"
                  >
                    <span class="label-pointer rounded text-center">A</span
                    ><input
                      type="radio"
                      class="required invalid is-invalid valid"
                      :value="5"
                      :id="this.pageList + 'opt_1'"
                      v-model="forms[questionData.field]"
                    />
                    <label
                      v-show="requiredError"
                      :id="this.pageList + 'stp_4_select_option-error'"
                      class="error"
                      for="stp_4_select_option"
                      >This field is required.</label
                    >
                    <span
                      class="label-content d-inline-block text-center rounded"
                      >{{ $t("answerOption1") }}</span
                    >
                  </label>
                </li>
                <li>
                  <label
                    :for="this.pageList + 'opt_2'"
                    class="rounded animate__animated animate__fadeInRight animate_50ms"
                    :class="[forms[questionData.field] == 4.5 ? 'active selectedRadio' : '']"
                  >
                    <span class="label-pointer rounded text-center">B</span
                    ><input
                      type="radio"
                      :value="4.5"
                      :id="this.pageList + 'opt_2'"
                      v-model="forms[questionData.field]"
                    />
                    <span
                      class="label-content d-inline-block text-center rounded"
                      >{{ $t("answerOption2") }}</span
                    >
                  </label>
                </li>
                <li>
                  <label
                    :for="this.pageList + 'opt_3'"
                    class="rounded animate__animated animate__fadeInRight animate_100ms"
                    :class="[forms[questionData.field] == 3.5 ? 'active selectedRadio' : '']"
                  >
                    <span class="label-pointer rounded text-center">C</span
                    ><input
                      type="radio"
                      :value="3.5"
                      :id="this.pageList + 'opt_3'"
                      v-model="forms[questionData.field]"
                    />
                    <span
                      class="label-content d-inline-block text-center rounded"
                      >{{ $t("answerOption3") }}</span
                    >
                  </label>
                </li>
                <li>
                  <label
                    :for="this.pageList + 'opt_4'"
                    class="step_3 rounded animate__animated animate__fadeInRight animate_150ms"
                    :class="[forms[questionData.field] == 3 ? 'active selectedRadio' : '']"
                  >
                    <span class="label-pointer rounded text-center">D</span
                    ><input
                      type="radio"
                      :id="this.pageList + 'opt_4'"
                      v-model="forms[questionData.field]"
                      :value="3"
                    />
                    <span
                      class="label-content d-inline-block text-center rounded"
                      >{{ $t("answerOption4") }}</span
                    >
                  </label>
                </li>
                <li>
                  <label
                    :for="this.pageList + 'opt_5'"
                    class="step_3 rounded animate__animated animate__fadeInRight animate_150ms"
                    :class="[forms[questionData.field] == 2.5 ? 'active selectedRadio' : '']"
                  >
                    <span class="label-pointer rounded text-center">E</span
                    ><input
                      type="radio"
                      :id="this.pageList + 'opt_5'"
                      v-model="forms[questionData.field]"
                      :value="2.5"
                    />
                    <span
                      class="label-content d-inline-block text-center rounded"
                      >{{ $t("answerOption5") }}</span
                    >
                  </label>
                </li>
              </ul>
            </div>
          </span>
          <span v-else-if="questionData?.type == 'q3'">
            <div class="form_items radio-list">
              <ul class="text-uppercase list-unstyled">
                <li>
                  <label
                    :for="this.pageList + 'opt_1'"
                    class="rounded animate__animated animate__fadeInRight animate_25ms"
                    :class="[forms[questionData.field] == true ? 'active' : '']"
                  >
                    <span class="label-pointer rounded text-center">A</span
                    ><input
                      type="radio"
                      class="required invalid is-invalid valid"
                      :value="true"
                      :id="this.pageList + 'opt_1'"
                      v-model="forms[questionData.field]"
                    />
                    <label
                      v-show="requiredError"
                      :id="this.pageList + 'stp_4_select_option-error'"
                      class="error"
                      for="stp_4_select_option"
                      >This field is required.</label
                    >
                    <span
                      class="label-content d-inline-block text-center rounded"
                      >{{$t('yes')}}</span
                    >
                  </label>
                </li>
                <li>
                  <label
                    :for="this.pageList + 'opt_2'"
                    class="rounded animate__animated animate__fadeInRight animate_50ms"
                    :class="[
                      forms[questionData.field] == false ? 'active' : '',
                    ]"
                  >
                    <span class="label-pointer rounded text-center">B</span
                    ><input
                      type="radio"
                      :value="false"
                      :id="this.pageList + 'opt_2'"
                      v-model="forms[questionData.field]"
                    />
                    <span
                      class="label-content d-inline-block text-center rounded"
                      >{{$t('no')}}</span
                    >
                  </label>
                </li>
              </ul>
            </div>
          </span>
          <span v-else>
            <div class="form_items">
              <textarea
                name=""
                class="form-control"
                v-model="forms[questionData.field]"
              ></textarea>
            </div>
            <label
              v-show="requiredError"
              :id="this.pageList + 'stp_4_select_option-error'"
              class="error"
              for="stp_4_select_option"
              >All Fields are Required.</label
            >
          </span>
        </div>
      </div>
    </div>
    <!---------- Form Button ---------->
    <div class="form_btn py-5 d-flex justify-content-center align-items-center">
      <button
        v-show="pageList !== 1"
        type="button"
        class="js-btn-prev f_btn rounded-pill disable text-uppercase"
        :id="this.pageList + 'prevBtn'"
        @click="prevPage"
      >
        <span><i class="fas fa-arrow-left pe-1"></i></span> {{ $t("lastQuestion") }}
      </button>
      <button
        v-show="lastQuestion !== pageList"
        type="button"
        class="js-btn-next f_btn rounded-pill active text-uppercase"
        :id="this.pageList + 'nextBtn'"
        @click="nextPage"
      >
      {{ $t("nextQuestion") }} <span><i class="fas fa-arrow-right ps-1"></i></span>
      </button>
      <button
        v-show="lastQuestion == pageList"
        type="submit"
        class="f_btn rounded-pill active text-uppercase"
        :id="this.pageList + 'nextBtn'"
        @click="submitData"
      >
      {{ $t("submitQuestion") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionData",
  components: {},
  props: {
    questionData: {},
    datas: {},
    currentQuestion: Number,
    pageList: Number,
    lastQuestion: Number,
    locales: String,
  },
  data() {
    return {
      isPopoverVisible: false,
      popoverPosition: {},
      active: false,
      forms: {},
      checked: 0,
      selectedQ1: [],
      requiredError: false,
      langs: [
        {
          name:"Indonesia",
          value:"id"
        },
        {
          name:"English",
          value:"en"
        }],
      options: [
        { name: "5", disabled: false },
        { name: "4", disabled: false },
        { name: "3", disabled: false },
        { name: "2", disabled: false },
        { name: "1", disabled: false },
      ],
      replaced: 0,
      locale: "",
      selectedLang: "",
    };
  },
  computed: {},
  watch: {
    currentQuestion() {
      if (this.currentQuestion == this.pageList) {
        this.active = true;
        this.checkHeaderQuestion();
      }
    },
    locales: {
      handler(newVal) {
        this.selectedLang = newVal;
      },
      immediate: true,
      deep: true,
    },
    forms: {
      handler(newVal) {
        if (this.checked == 1) {
          if (
            this.questionData?.type == "q2" ||
            this.questionData?.type == "q3" ||
            this.questionData?.type == "q4"
          ) {
            this.$emit(
              "sendData",
              this.questionData?.type,
              this.questionData?.field,
              newVal?.val
            );
          } else {
            this.$emit(
              "sendData",
              this.questionData?.type,
              this.questionData?.field,
              newVal
            );
          }
        } else {
          this.checked = 1;
        }
      },
      immediate: true,
      deep: true,
    },
    datas: {
      handler(newVal) {
        if (this.replaced < 2) {
          if (newVal.question_1 == null) {
            newVal.question_1 = "";
          }
          if (newVal.question_2 == null) {
            newVal.question_2 = "";
          }
          if (newVal.question_3 == null) {
            newVal.question_3 = "";
          }
          if (newVal.question_4 == null) {
            newVal.question_4 = "";
          }
          if (newVal.question_5 == null) {
            newVal.question_5 = "";
          }
          this.forms = newVal;
          this.replaced++;

          // console.log(newVal);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    togglePopover() {
        this.isPopoverVisible = !this.isPopoverVisible;
        this.updatePopoverPosition();
    },
    hidePopover() {
        this.isPopoverVisible = false;
    },
    updatePopoverPosition(){
      const isMobile = window.innerWidth <= 768;
      console.log(isMobile);
      if (isMobile) {
            // Center the popover in the viewport on mobile
            this.popoverPosition = {
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               position: 'fixed', // Fix it to the viewport center
               width:'300px !important'
            };
         } else {
            // Position popover near the button for larger screens
            this.popoverPosition = {
               transform: 'translate(0, 0)',
               position: 'absolute'
            };
         }
    },
    langChanged() {
      this.$emit("langChanged", this.selectedLang);
    },
    nextPage() {
      this.requiredError = false;
      if (
        this.questionData?.type == "q2" ||
        this.questionData?.type == "q3" ||
        this.questionData?.type == "q4"
      ) {
        if (this.forms[this.questionData.field] == undefined) {
          this.requiredError = true;
        } else {
          if (this.forms[this.questionData.field] === "") {
            this.requiredError = true;
          }
        }
      } else if (this.questionData?.type == "register") {
        if (this.forms.departement == undefined) {
          this.requiredError = true;
        } else {
          if (this.forms?.departement == "") {
            this.requiredError = true;
          }
        }
      } else if (this.questionData.type == "q1") {
        if (
          this.forms?.question_1 == undefined ||
          this.forms?.question_1 == "" ||
          this.forms?.question_2 == undefined ||
          this.forms?.question_2 == "" ||
          this.forms?.question_3 == undefined ||
          this.forms?.question_3 == "" ||
          this.forms?.question_4 == undefined ||
          this.forms?.question_4 == "" ||
          this.forms?.question_5 == undefined ||
          this.forms?.question_5 == "" ||
          this.forms?.question_5 == null ||
          this.forms?.question_4 == null ||
          this.forms?.question_3 == null ||
          this.forms?.question_2 == null ||
          this.forms?.question_1 == null
        ) {
          this.requiredError = true;
        } 
      }

      if (!this.requiredError) {
        this.$emit("nextPage");
      }
    },
    prevPage() {
      this.$emit("prevPage");
    },
    changeSelect(ev, field) {
      const index = ev.target.selectedIndex;
      const value = ev.target.value;
      if (value !== "") {
        this.options = this.options.map((o, i) => {
          // Disabled Selected Value
          if (i === index - 1) {
            o.disabled = true;
          }

          // Assign Selected Value perfield
          this.selectedQ1[field] = value;

          // Recheck Disabled Item
          if (o.disabled) {
            // Set Default False
            var checked = false;
            // Loop SelectedValue Data to Validate (if option disabled but not found in selected value set disabled to false)
            this.selectedQ1.forEach((item) => {
              if (o.name == item) {
                checked = true;
              }
            });

            o.disabled = checked;
          }

          return o;
        });
      } else {
        // Undisabled unselected item
        const removedField = this.selectedQ1[field];
        this.options = this.options.map((o) => {
          // If Option name = removed value => enable option
          if (o.name === removedField) {
            o.disabled = false;
          }
          return o;
        });
        this.selectedQ1[field] = null;
      }
    },
    checkSelect(n) {
      var check = this.selectedQ1.filter(function (elm) {
        if (n == elm) {
          return true;
        }
      });
      if (check) {
        return true;
      } else {
        return false;
      }
    },
    submitData() {
      this.requiredError = false;
      if (this.questionData?.type == "q2") {
        if (this.forms.val == undefined) {
          this.requiredError = true;
        } else {
          if (this.forms?.val == "") {
            this.requiredError = true;
          }
        }
      } else if (this.questionData?.type == "register") {
        if (this.forms.departement == undefined) {
          this.requiredError = true;
        } else {
          if (this.forms?.departement == "") {
            this.requiredError = true;
          }
        }
      }

      if (!this.requiredError) {
        this.$emit("submitData");
      }
    },
    checkHeaderQuestion(){
      if(this.questionData?.type == 'q1'){
        if(this.selectedQ1.length <= 0){
          // Reassign Value for Step 2
          if(this.forms.question_1  !== undefined && this.forms?.question_1 !== "" && this.forms?.question_1 !== null){
            this.selectedQ1[0] = this.forms?.question_1;
          }

          if(this.forms.question_2  !== undefined && this.forms?.question_2 !== "" && this.forms?.question_2 !== null){
            this.selectedQ1[1] = this.forms?.question_2;
          }

          if(this.forms.question_3  !== undefined && this.forms?.question_3 !== "" && this.forms?.question_3 !== null){
            this.selectedQ1[2] = this.forms?.question_3;
          }
          
          if(this.forms.question_4  !== undefined && this.forms?.question_4 !== "" && this.forms?.question_4 !== null){
            this.selectedQ1[3] = this.forms?.question_4;
          }

          if(this.forms.question_5  !== undefined && this.forms?.question_5 !== "" && this.forms?.question_5 !== null){
            this.selectedQ1[4] = this.forms?.question_5;
          }
          this.options = this.options.map((o) => {
            var checked = false;
            this.selectedQ1.forEach((item)=> {
              if(o.name == item){
                checked = true;
              }
            });
            o.disabled = checked;

            return o;
          });

        }
      }
    }
  },
  events: {},
  created: function () {

  },
  mounted:function(){
    this.checkHeaderQuestion();
  }
};
</script>

<style scoped>
.popover-container {
   position: relative;
   display: inline-block;
}
.popover-content {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.6);
   color:white;
   border: 1px solid #ddd;
   border-radius: 4px;
   padding: 10px;
   z-index: 1000;
   width: 400px;
}
.help-icon {
    width: 20px; /* Icon width */
    height: 20px; /* Icon height */
    fill: white; /* Icon color */
}
.bordered{
  border: solid black 5px;
}
.selectedRadio{
  font-weight: bold;
  color: white;
}
.form_img img {
  /* width: 80%; */
  width: 100%;
}
.form_content {
  padding-top: 6rem;
  display: none;
}
.form_items label {
  padding: 0rem 3rem;
  cursor: pointer;
}
.form_items .label-pointer {
  font-weight: 600;
  margin-top: 1rem;
  margin-right: 0.5rem;
  padding: 1rem 1.5rem;
  background-color: #d2e9d9;
  border: 4px solid transparent;
  cursor: pointer;
}
.form_items .label-content {
  min-width: 160px;
  margin-top: 1rem;
  padding: 0.8rem 1rem;
  background-color: #d2e9d9;
  border: 4px solid transparent;
  cursor: pointer;
}
.form_items .active .label-pointer,
.form_items .active .label-content {
  background-color: #ab0635;
  -webkit-box-shadow: 0 0 3px #b8b9b8;
  box-shadow: 0 0 3px #b8b9b8;
  border: 4px solid #c8edd2;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.form_items input {
  opacity: 0;
  position: absolute;
}
.form_items .error {
  color: red;
  font-size: 10px;
  position: absolute;
  top: -20px;
  left: 0px;
}

.multisteps_form_panel.active .form_content {
  display: block;
}
.form_btn .f_btn {
  color: #ffffff;
  font-size: 0.9375rem;
  font-weight: 800;
  padding: 0.75rem 2rem;
  outline: none;
  border: none;
}
.form_btn .disable {
  color: #ffffff;
  background-color: #172126;
  margin-right: 0.625rem;
}
.form_btn .disable:hover {
  color: #000000;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.form_btn .active {
  color: #ffffff;
  background-color: #172126;
}
.form_btn .active:hover {
  color: #000000;
  background-color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
/* ==================================================
*	01 - media screen and (max-width: 1440px)
*	02 - media screen and (max-width: 1280px)
*	03 - media screen and (max-width: 1199px)
*	04 - media screen and (max-width: 991px)
*	05 - media screen and (max-width: 767px)
*	06 - media screen and (max-width: 680px)
*	07 - media screen and (max-width: 580px)
*	08 - media screen and (max-width: 480px)
*	09 - media screen and (max-width: 380px)
*	10 - media screen and (max-width: 320px)
================================================== */
@media screen and (max-width: 1199.98px) {
  .form_title h1 {
    font-size: 3rem;
  }
  .form-group label {
    color:black !important;
    font-weight: bold;
  }
  .form_items label {
    padding: 0rem 1rem;
  }
  .form_items span {
    padding: 0.5rem 0.7rem;
  }

  .form_btn .f_btn {
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
  }
}
@media screen and (max-width: 991.98px) {
  .register-title {
    color: white !important;
  }
  .step_bar_content {
    text-align: center;
    padding-left: 0 !important;
  }

  .progress_bar {
    text-align: center;
    padding-left: 0 !important;
    display: block !important;
  }

  .form_content {
    padding-top: 3rem;
  }

  .form_title {
    text-align: center;
    padding-left: 0 !important;
  }

  .form_img img {
    width: 40%;
  }

  .form_items {
    text-align: center;
    padding-top: 2rem;
  }

  .form_btn .f_btn {
    font-size: 0.8rem;
    padding: 1rem 2rem;
  }
}
@media screen and (max-width: 767.98px) {
  
  .form-group label {
    color:white !important
  }
  .form_title h1 {
    font-size: 2rem;
    color:black !important;
  }

  .form_btn .f_btn {
    padding: 0.8rem 1rem;
  }
}
@media screen and (max-width: 575.98px) {
  .form_title h1 {
    font-size: 2rem;
    color:black !important;
  }

  .form_img img {
    display: none;
  }

  .form_btn .f_btn {
    padding: 0.8rem 1rem;
  }
}
@media screen and (max-width: 379.98px) {
  .step_bar_content h5 {
    font-size: 0.8rem;
    font-weight: 600;
  }

  .step {
    height: 1.6rem;
    width: 1.6rem;
    font-size: 0.8rem;
    line-height: 1.7rem;
  }
  .step:not(:last-child) {
    margin-right: 1.5rem;
  }
  .step:after {
    left: 30px;
    width: 1.1rem;
    height: 0.125rem;
  }

  .form_title {
    padding: 0rem;
  }
  .form_title h1 {
    font-size: 1.5rem;
    color:black !important;
  }

  .form_items label {
    padding: 0rem;
  }
  .form_items span {
    padding: 0.5rem 0.7rem;
  }
  .form_items .label-content {
    min-width: 150px;
  }

  .form_btn .f_btn {
    font-size: 0.6rem;
    padding: 0.8rem;
  }
}
</style>
