<template>
  <div class="wrapper position-relative" :class="[activePanel > 1 ? 'background1' : 'background2']">
    <img v-show="activePanel > 1" v-bind:src="require('@/assets/images/linc-group-floating.png')" alt="Linc Group Logo" class="floating-right-top">
    <img v-show="activePanel > 1" v-bind:src="require('@/assets/images/linc-group-child-list.png')" alt="Linc Group Logo" class="floating-left-bottom">
    <div class="container-fluid px-5">
      <div class="step_bar_content ps-5 pt-5">
        <h1 class="appTitle text-uppercase d-inline-block">
          {{ $t('customerSurvey') }}
        </h1>
      </div>
      <div class="progress_bar steps_bar mt-3 ps-5 d-inline-block">
        <!-- <div
          class="step rounded-pill d-inline-block text-center position-relative"
          :class="[activePanel >= n ? 'active current' : '']"
          v-for="n in category.length"
          :key="n"
        >
          {{ n }}
        </div> -->
        <div
          class="step rounded-pill d-inline-block text-center position-relative"
          :class="[checkCategory(n) ? 'active current' : '']"
          v-for="n in category.length"
          :key="n"
        >
          {{ n }}
        </div>
      </div>
      <form
        class="multisteps_form position-relative"
        id="wizard"
        @submit.prevent="preventSubmit"
        :style="{ height: componentHeight }"
        novalidate="novalidate"
        v-show="questionList.length > 0"
      >
        <!------------------------- Step-1 ----------------------------->
        <div
          class="multisteps_form_panel"
          :class="[
            activePanel == index + 1 ? 'active slideVert' : '',
            'Question_' + index,
          ]"
          data-animation="slideVert"
          style="height: auto !important"
          v-for="(item, index) in questionList"
          :key="index"
          :id="'question_' + index"
          ref="questions"
        >
          <Question
            :questionData="item"
            :locales="locale"
            :datas="forms"
            :lastQuestion="questionList.length"
            :currentQuestion="activePanel"
            @sendData="getDataQuestion"
            :pageList="index+1"
            @nextPage="nextPanel"
            @prevPage="prevPanel"
            @submitData="submitData"
            @langChanged="langChanged"
          ></Question>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Question from "@/components/QuestionComponent.vue";
export default {
  name: "SurveyQuestion",
  components: {
    Question,
  },
  props: {},
  data() {
    return {
      forms: {},
      activePanel: 1,
      totalQuestion: 20,
      category: [
        "register",
        "rank",
        "Customer Service",
        "Operational",
        "Competitiveness",
        "Technical Support",
        "Compliance",
        "Recomend",
        "Impression",
        "Correction",
      ],
      questionList: [],
      locale: "",
      onLoad:false,
      componentHeight: "500px",
      imageType:"vector",
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    submitData() {
      this.isLoading = true;
      const baseURI =
        this.$settings.endPointCeisa +
        "v1/sales-survey/send-data/" +
        this.$route.params.type +
        "/" +
        this.$route.params.id;
      return this.$http
        .post(baseURI, this.forms, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false;
          if (response.status == 200) {
            localStorage.removeItem("activePanel");
            localStorage.removeItem("questionId");
            localStorage.removeItem("formData");
            this.$router.push("/done");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 401) {
                this.logout();
              } else if (error.response.status === 403) {
                this.$router.push("/authorized-error");
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    calculateHeight() {
      // let questions =this.$refs.questions;
      // console.log(questions);
      // active panel - 1 karena activePanel dimulai dari 1
      // let questionData = questions[this.activePanel-1];
      // console.log(this.activePanel);
      // console.log(questions[this.activePanel]._prevClass);
      // console.log(questionData.offsetHeight);
      // console.log(questionData._prevClass);
    },
    nextPanel() {
      this.activePanel = this.activePanel + 1;
      this.saveLocalStorage();
      this.calculateHeight();
    },
    prevPanel() {
      this.activePanel = this.activePanel - 1;
      this.saveLocalStorage();
      this.calculateHeight();
    },
    saveLocalStorage() {
      localStorage.setItem("activePanel", this.activePanel);
      localStorage.setItem("questionId", this.$route.params.id);
      let dummy = JSON.stringify(this.forms);
      localStorage.setItem("formData", dummy);
    },
    loadLocalStorage() {
      if (localStorage.getItem("questionId") !== null) {
        if (localStorage.getItem("questionId") == this.$route.params.id) {
          if (localStorage.getItem("activePanel") !== null) {
            this.activePanel = parseInt(localStorage.getItem("activePanel"));
          }

          if (localStorage.getItem("formData") !== null) {
            let dummy = JSON.parse(localStorage.getItem("formData"));
            this.forms = dummy;
          }
        } else {
          localStorage.removeItem("activePanel");
          localStorage.removeItem("formData");
        }
      }
    },
    getDataQuestion(type, field, data) {
      if (type == "q2" || type == "q3" || type == "q4") {
        if (data != null || data != undefined) {
          this.$set(this.forms, field, data);
        }
      } else {
        if (type == "register") {
          if (data.departement != null || data.departement != undefined) {
            this.$set(this.forms, "departement", data.departement);
          }
        } else if (type == "q1") {
          if (data.question_1 != null || data.question_1 != undefined) {
            this.$set(this.forms, "question_1", data.question_1);
          }
          if (data.question_2 != null || data.question_2 != undefined) {
            this.$set(this.forms, "question_2", data.question_2);
          }
          if (data.question_3 != null || data.question_3 != undefined) {
            this.$set(this.forms, "question_3", data.question_3);
          }
          if (data.question_4 != null || data.question_4 != undefined) {
            this.$set(this.forms, "question_4", data.question_4);
          }
          if (data.question_5 != null || data.question_5 != undefined) {
            this.$set(this.forms, "question_5", data.question_5);
          }
        }
      }
    },
    checkCategory(n) {
      if (this.questionList.length > 0) {
        if (this.questionList[this.activePanel - 1].categoryId >= n) {
          return true;
        } else {
          return false;
        }
      }
    },
    loadData() {
      this.isLoading = true;
      const baseURI =
        this.$settings.endPointCeisa +
        "v1/sales-survey/detail-data/" +
        this.$route.params.type +
        "/" +
        this.$route.params.id;
      return this.$http
        .get(baseURI, { timeout: 10000 })
        .then((response) => {
          this.isLoading = false;
          let res = response.data.datas;
          if(res === null){
            this.$router.push('/not-found');
          }
          if (res.status != "NEW") {
            this.$router.push("/done");
          } else {
            this.forms = res;
            this.loadQuestion(this.$route.params.type);
            this.loadLocalStorage();
          }
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.code == "ECONNABORTED") {
            this.error(this.$t("check_internet_connection_txt"));
          } else {
            if (error.response) {
              if (error.response.status === 422) {
                this.errors = error.response.data.errors;
                this.resultError(error.response.data.errors);
              } else if (error.response.status === 401) {
                this.logout();
              } else if (error.response.status === 403) {
                this.$router.push("/authorized-error");
              } else if (error.response.status === 500) {
                this.$router.push("/server-error");
              } else {
                this.$router.push("/page-not-found");
              }
            }
          }
        });
    },
    loadQuestion(type) {
      if (type.toUpperCase() == "CML") {
        if(this.imageType == "real"){
          this.loadQuestionCML();
        }else{
          this.loadQuestionCMLAnimate();
        }
      } else if (type.toUpperCase() == "BP") {
        if(this.imageType == "real"){
          this.loadQuestionBP();
        }else{
          this.loadQuestionBPAnimate();
        }
      } else {
        this.$router.push("/page-not-found");
      }
    },
    // Ga Kepake
    loadQuestionCML() {
      this.questionList = [
        {
          categoryId: 1,
          field: "register",
          title:
            this.$t('registrationTxt')+" &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
          type: "register",
          src: require("@/assets/images/linc-logo.png"),
          border:false,
        },
        {
          categoryId: 2,
          field: "question_1",
          title: this.$t("cmlQuestion1"),
          type: "q1",
          // src: require("@/assets/images/linc-logo.png"),
          border:false,
        },
        // Customer Service
        {
          categoryId: 3,
          field: "question_6",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.1 ' +
            this.$t("cmlCustomerServiceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb270ee09c7.jpg"),
        },
        {
          categoryId: 3,
          field: "question_7",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.2 ' +
            this.$t("cmlCustomerServiceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb270ee09c7.jpg"),
        },
        {
          categoryId: 3,
          field: "question_8",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.3 ' +
            this.$t("cmlCustomerServiceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb270ee09c7.jpg"),
        },
        {
          categoryId: 3,
          field: "question_9",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.4 ' +
            this.$t("cmlCustomerServiceSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb270ee09c7.jpg"),
        },
        // Operational
        {
          categoryId: 4,
          field: "question_10",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.1 ' +
            this.$t("cmlOperationalSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3663_1.jpg"),
        },
        {
          categoryId: 4,
          field: "question_11",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.2 ' +
            this.$t("cmlOperationalSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3663_1.jpg"),
        },
        {
          categoryId: 4,
          field: "question_12",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.3 ' +
            this.$t("cmlOperationalSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3663_1.jpg"),
        },
        {
          categoryId: 4,
          field: "question_13",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.4 ' +
            this.$t("cmlOperationalSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3663_1.jpg"),
        },
        {
          categoryId: 4,
          field: "question_14",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.5 ' +
            this.$t("cmlOperationalSub5") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3663_1.jpg"),
        },
        {
          categoryId: 4,
          field: "question_15",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.6 ' +
            this.$t("cmlOperationalSub6") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3663_1.jpg"),
        },
        // Competitiveness
        {
          categoryId: 5,
          field: "question_16",
          title:
            ' <h2 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">3.1 ' +
            this.$t("cmlCompetitivenessSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb5dd8a4541.jpg"),
        },
        {
          categoryId: 5,
          field: "question_17",
          title:
            ' <h2 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">3.2 ' +
            this.$t("cmlCompetitivenessSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb5dd8a4541.jpg"),
        },
        {
          categoryId: 5,
          field: "question_18",
          title:
            ' <h2 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">3.3 ' +
            this.$t("cmlCompetitivenessSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb5dd8a4541.jpg"),
        },
        // Technical Support
        {
          categoryId: 6,
          field: "question_19",
          title:
            ' <h2 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">4.1 ' +
            this.$t("cmlTechnicalSupportSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3884.jpg"),
        },
        {
          categoryId: 6,
          field: "question_20",
          title:
            ' <h2 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">4.2 ' +
            this.$t("cmlTechnicalSupportSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3884.jpg"),
        },
        {
          categoryId: 6,
          field: "question_21",
          title:
            ' <h2 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">4.3 ' +
            this.$t("cmlTechnicalSupportSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3884.jpg"),
        },
        // Compilance
        {
          categoryId: 7,
          field: "question_22",
          title:
            ' <h2 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">5.1 ' +
            this.$t("cmlComplianceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3727.jpg"),
        },
        {
          categoryId: 7,
          field: "question_23",
          title:
            ' <h2 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">5.2 ' +
            this.$t("cmlComplianceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3727.jpg"),
        },
        {
          categoryId: 7,
          field: "question_24",
          title:
            ' <h2 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">5.3 ' +
            this.$t("cmlComplianceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/50-DSC_3727.jpg"),
        },
        {
          categoryId: 8,
          field: "question_25",
          title:
            '<h3 class="text-white" style="text-align: left;">' +
            this.$t("recomendation") +
            "</h3>",
          type: "q3",
          src: require("@/assets/images/66bb78b23f5cd.jpg"),
        },
        {
          categoryId: 9,
          field: "question_26",
          title:
            '<h3 class="text-white" style="text-align: left;">' +
            this.$t("goodImpression") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/66bb78b23f5cd.jpg"),
        },
        {
          categoryId: 10,
          field: "question_27",
          title:
            '<h3 class="text-white" style="text-align: left;">' +
            this.$t("correction") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/66bb78b172f58.jpg"),
        },
      ];
    },
    loadQuestionCMLAnimate() {
      this.questionList = [
        {
          categoryId: 1,
          field: "register",
          title:
            this.$t('registrationTxt')+" &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
          type: "register",
          src: require("@/assets/images/linc-logo.png"),
          border:false,
          isPopOver:false,
        },
        {
          categoryId: 2,
          field: "question_1",
          title: this.$t("cmlQuestion1"),
          type: "q1",
          src: require("@/assets/images/vector/671f4e0789735.png"),
          border:false,
          isPopOver:false,
        },
        // Customer Service
        {
          categoryId: 3,
          field: "question_6",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.1 ' +
            this.$t("cmlCustomerServiceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage: "<p>"+this.$t('cmlHelpQuestion_1')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_2')+"</p>"
        },
        {
          categoryId: 3,
          field: "question_7",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.2 ' +
            this.$t("cmlCustomerServiceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_3')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_4')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_5')+"</p>"
        },
        {
          categoryId: 3,
          field: "question_8",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.3 ' +
            this.$t("cmlCustomerServiceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_6')+"</p>"
        },
        {
          categoryId: 3,
          field: "question_9",
          title:
            ' <h2 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">1.4 ' +
            this.$t("cmlCustomerServiceSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_7')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_8')+"</p>"
        },
        // Operational
        {
          categoryId: 4,
          field: "question_10",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.1 ' +
            this.$t("cmlOperationalSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6e092e7f91.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_9')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_11",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.2 ' +
            this.$t("cmlOperationalSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f52289081c.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_10')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_11')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_12')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_12",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.3 ' +
            this.$t("cmlOperationalSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66cbf4bc1026f.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_13')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_13",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.4 ' +
            this.$t("cmlOperationalSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f4575d03a5.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_14')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_15')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_16')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_14",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.5 ' +
            this.$t("cmlOperationalSub5") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f456572a0c.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_17')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_18')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_19')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_15",
          title:
            ' <h2 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">2.6 ' +
            this.$t("cmlOperationalSub6") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f59197e655.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_20')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_21')+"</p>"
        },
        // Competitiveness
        {
          categoryId: 5,
          field: "question_16",
          title:
            ' <h2 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">3.1 ' +
            this.$t("cmlCompetitivenessSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/6720636829aae.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_22')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_23')+"</p>"
        },
        {
          categoryId: 5,
          field: "question_17",
          title:
            ' <h2 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">3.2 ' +
            this.$t("cmlCompetitivenessSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f5918d029d.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_24')+"</p>"
        },
        {
          categoryId: 5,
          field: "question_18",
          title:
            ' <h2 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">3.3 ' +
            this.$t("cmlCompetitivenessSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f5919e8598.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_25')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_26')+"</p>"
        },
        // Technical Support
        {
          categoryId: 6,
          field: "question_19",
          title:
            ' <h2 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">4.1 ' +
            this.$t("cmlTechnicalSupportSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/67206367b8b9a.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_27')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_28')+"</p>"
        },
        {
          categoryId: 6,
          field: "question_20",
          title:
            ' <h2 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">4.2 ' +
            this.$t("cmlTechnicalSupportSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/67206369eb5f9.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_29')+"</p>"
        },
        {
          categoryId: 6,
          field: "question_21",
          title:
            ' <h2 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">4.3 ' +
            this.$t("cmlTechnicalSupportSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/67206368ca35e.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_30')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_31')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_32')+"</p>"
        },
        // Compilance
        {
          categoryId: 7,
          field: "question_22",
          title:
            ' <h2 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">5.1 ' +
            this.$t("cmlComplianceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f591933b58.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_33')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_34')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_35')+"</p>"
        },
        {
          categoryId: 7,
          field: "question_23",
          title:
            ' <h2 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">5.2 ' +
            this.$t("cmlComplianceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c80f0b448ee.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_36')+"</p>"
        },
        {
          categoryId: 7,
          field: "question_24",
          title:
            ' <h2 class="text-white" style="text-align: left;">5.' +
            this.$t("cmlComplianceHeader") +
            '</h2><h3 class="text-white" style="text-align: left;">5.3 ' +
            this.$t("cmlComplianceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f457529c5e.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('cmlHelpQuestion_37')+"</p>"+"<p>"+this.$t('cmlHelpQuestion_38')+"</p>"
        },
        {
          categoryId: 8,
          field: "question_25",
          title:
            '<h3 class="text-white" style="text-align: left;">' +
            this.$t("recomendation") +
            "</h3>",
          type: "q3",
          src: require("@/assets/images/vector/66c84523226ba.png"),
          border:false,
        },
        {
          categoryId: 9,
          field: "question_26",
          title:
            '<h3 class="text-white" style="text-align: left;">' +
            this.$t("goodImpression") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/vector/671f591a50ffe.png"),
          border:false,
        },
        {
          categoryId: 10,
          field: "question_27",
          title:
            '<h3 class="text-white" style="text-align: left;">' +
            this.$t("correction") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/vector/66c8472ff1ab4.png"),
          border:false,
        },
      ];
    },
    loadQuestionBP() {
      this.questionList = [
        {
          categoryId: 1,
          field: "register",
          title:
            this.$t("registrationTxt") +
            "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
          type: "register",
          src: require("@/assets/images/linc-logo.png"),
          border:false,
        },
        {
          categoryId: 2,
          field: "question_1",
          title: this.$t("cmlQuestion1"),
          type: "q1",
          src: require("@/assets/images/linc-logo.png"),
          border:false,

        },
        // Customer Service
        {
          categoryId: 3,
          field: "question_6",
          title:
            ' <h1 style="text-align: left;">1.' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 style="text-align: left;">1.1 ' +
            this.$t("cmlCustomerServiceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb270ee09c7.jpg"),
        },
        {
          categoryId: 3,
          field: "question_7",
          title:
            ' <h1 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">1.2 ' +
            this.$t("cmlCustomerServiceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66c3104ced20d.jpg"),
        },
        {
          categoryId: 3,
          field: "question_8",
          title:
            ' <h1 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">1.3 ' +
            this.$t("cmlCustomerServiceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66c3104cc08ce.jpg"),
        },
        {
          categoryId: 3,
          field: "question_9",
          title:
            ' <h1 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">1.4 ' +
            this.$t("cmlCustomerServiceSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66c3104cc08ce.jpg"),
        },
        // Operational
        {
          categoryId: 4,
          field: "question_10",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.1 ' +
            this.$t("bpOperationalSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/operational.jpg"),
        },
        {
          categoryId: 4,
          field: "question_11",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.2 ' +
            this.$t("bpOperationalSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/operational.jpg"),
        },
        {
          categoryId: 4,
          field: "question_12",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.3 ' +
            this.$t("bpOperationalSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/operational.jpg"),
        },
        {
          categoryId: 4,
          field: "question_13",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.4 ' +
            this.$t("bpOperationalSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/operational.jpg"),
        },
        // Competitiveness
        {
          categoryId: 5,
          field: "question_14",
          title:
            ' <h1 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">3.1 ' +
            this.$t("cmlCompetitivenessSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb5dd8a4541.jpg"),
        },
        {
          categoryId: 5,
          field: "question_15",
          title:
            ' <h1 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">3.2 ' +
            this.$t("cmlCompetitivenessSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb5dd8a4541.jpg"),
        },
        {
          categoryId: 5,
          field: "question_16",
          title:
            ' <h1 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">3.3 ' +
            this.$t("cmlCompetitivenessSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb5dd8a4541.jpg"),
        },
        // Technical Support
        {
          categoryId: 6,
          field: "question_17",
          title:
            ' <h1 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">4.1 ' +
            this.$t("cmlTechnicalSupportSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb64ef631b7.jpg"),
        },
        {
          categoryId: 6,
          field: "question_18",
          title:
            ' <h1 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">4.2 ' +
            this.$t("bpTechnicalSupportSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb64ef631b7.jpg"),
        },
        {
          categoryId: 6,
          field: "question_19",
          title:
            ' <h1 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">4.3 ' +
            this.$t("bpTechnicalSupportSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb64ef631b7.jpg"),
        },
        // Compliance
        {
          categoryId: 7,
          field: "question_20",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.1 ' +
            this.$t("bpComplianceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb6ce0dfe05.jpg"),
        },
        {
          categoryId: 7,
          field: "question_21",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.2 ' +
            this.$t("bpComplianceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb6ce0dfe05.jpg"),
        },
        {
          categoryId: 7,
          field: "question_22",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.3 ' +
            this.$t("bpComplianceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb6ce0dfe05.jpg"),
        },
        {
          categoryId: 7,
          field: "question_23",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.4 ' +
            this.$t("bpComplianceSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/66bb6ce0dfe05.jpg"),
        },
        {
          categoryId: 8,
          field: "question_24",
          title:
            '<h2 class="text-white" style="text-align: left;">' +
            this.$t("recomendation") +
            "</h3>",
          type: "q3",
          src: require("@/assets/images/66bb78b23f5cd.jpg"),
        },
        {
          categoryId: 9,
          field: "question_25",
          title:
            '<h2 class="text-white" style="text-align: left;">' +
            this.$t("goodImpression") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/66bb78b23f5cd.jpg"),
        },
        {
          categoryId: 10,
          field: "question_26",
          title:
            '<h2 class="text-white" style="text-align: left;">' +
            this.$t("correction") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/66bb78b172f58.jpg"),
        },
      ];
    },
    loadQuestionBPAnimate() {
      this.questionList = [
        {
          categoryId: 1,
          field: "register",
          title:
            this.$t("registrationTxt") +
            "&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;",
          type: "register",
          src: require("@/assets/images/linc-logo.png"),
          border:false,
        },
        {
          categoryId: 2,
          field: "question_1",
          title: this.$t("cmlQuestion1"),
          type: "q1",
          src: require("@/assets/images/vector/671f4e0789735.png"),
            border:false,

        },
        // Customer Service
        {
          categoryId: 3,
          field: "question_6",
          title:
            ' <h1 style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 style="text-align: left;">1.1 ' +
            this.$t("cmlCustomerServiceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_1')+"</p>"+"<p>"+this.$t('bpHelpQuestion_2')+"</p>"
        },
        {
          categoryId: 3,
          field: "question_7",
          title:
            ' <h1 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">1.2 ' +
            this.$t("cmlCustomerServiceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_3')+"</p>"+"<p>"+this.$t('bpHelpQuestion_4')+"</p>"+"<p>"+this.$t('bpHelpQuestion_5')+"</p>"
        },
        {
          categoryId: 3,
          field: "question_8",
          title:
            ' <h1 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">1.3 ' +
            this.$t("cmlCustomerServiceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_6')+"</p>"
        },
        {
          categoryId: 3,
          field: "question_9",
          title:
            ' <h1 class="text-white" style="text-align: left;">1. ' +
            this.$t("cmlCustomerServiceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">1.4 ' +
            this.$t("cmlCustomerServiceSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6d7baad307.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_7')+"</p>"+"<p>"+this.$t('bpHelpQuestion_8')+"</p>"
        },
        // Operational
        {
          categoryId: 4,
          field: "question_10",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.1 ' +
            this.$t("bpOperationalSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6de4f90d32.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_9')+"</p>"+"<p>"+this.$t('bpHelpQuestion_10')+"</p>"+"<p>"+this.$t('bpHelpQuestion_11')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_11",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.2 ' +
            this.$t("bpOperationalSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f457583654.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_12')+"</p>"+"<p>"+this.$t('bpHelpQuestion_13')+"</p>"+"<p>"+this.$t('bpHelpQuestion_14')+"</p>"+"<p>"+this.$t('bpHelpQuestion_15')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_12",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.3 ' +
            this.$t("bpOperationalSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c6e092e7f91.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_16')+"</p>"
        },
        {
          categoryId: 4,
          field: "question_13",
          title:
            ' <h1 class="text-white" style="text-align: left;">2. ' +
            this.$t("cmlOperationalHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">2.4 ' +
            this.$t("bpOperationalSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/6720636943679.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_17')+"</p>"
        },
        // Competitiveness
        {
          categoryId: 5,
          field: "question_14",
          title:
            ' <h1 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">3.1 ' +
            this.$t("cmlCompetitivenessSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/6720636829aae.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_18')+"</p>"+"<p>"+this.$t('bpHelpQuestion_19')+"</p>"
        },
        {
          categoryId: 5,
          field: "question_15",
          title:
            ' <h1 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">3.2 ' +
            this.$t("cmlCompetitivenessSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c800e1412db.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_20')+"</p>"
        },
        {
          categoryId: 5,
          field: "question_16",
          title:
            ' <h1 class="text-white" style="text-align: left;">3. ' +
            this.$t("cmlCompetitivenessHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">3.3 ' +
            this.$t("cmlCompetitivenessSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f5919e8598.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_21')+"</p>"+"<p>"+this.$t('bpHelpQuestion_22')+"</p>"
        },
        // Technical Support
        {
          categoryId: 6,
          field: "question_17",
          title:
            ' <h1 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">4.1 ' +
            this.$t("cmlTechnicalSupportSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/67206367b8b9a.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_23')+"</p>"+"<p>"+this.$t('bpHelpQuestion_24')+"</p>"+"<p>"+this.$t('bpHelpQuestion_25')+"</p>"
        },
        {
          categoryId: 6,
          field: "question_18",
          title:
            ' <h1 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">4.2 ' +
            this.$t("bpTechnicalSupportSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/67206368ca35e.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_26')+"</p>"+"<p>"+this.$t('bpHelpQuestion_27')+"</p>"+"<p>"+this.$t('bpHelpQuestion_28')
        },
        {
          categoryId: 6,
          field: "question_19",
          title:
            ' <h1 class="text-white" style="text-align: left;">4. ' +
            this.$t("cmlTechnicalSupportHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">4.3 ' +
            this.$t("bpTechnicalSupportSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c80a1f584e5.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_29')+"</p>"
        },
        // Compliance
        {
          categoryId: 7,
          field: "question_20",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.1 ' +
            this.$t("bpComplianceSub1") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/67206369a7feb.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_30')+"</p>"+"<p>"+this.$t('bpHelpQuestion_31')+"</p>"+"<p>"+this.$t('bpHelpQuestion_32')+"</p>"+"<p>"+this.$t('bpHelpQuestion_33')+"</p>"
        },
        {
          categoryId: 7,
          field: "question_21",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.2 ' +
            this.$t("bpComplianceSub2") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/6720636444e76.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_34')+"</p>"
        },
        {
          categoryId: 7,
          field: "question_22",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.3 ' +
            this.$t("bpComplianceSub3") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/671f4574ca55d.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_35')+"</p>"+"<p>"+this.$t('bpHelpQuestion_36')+"</p>"+"<p>"+this.$t('bpHelpQuestion_37')+"</p>"
        },
        {
          categoryId: 7,
          field: "question_23",
          title:
            ' <h1 class="text-white" style="text-align: left;">5. ' +
            this.$t("cmlComplianceHeader") +
            '</h1><h2 class="text-white" style="text-align: left;">5.4 ' +
            this.$t("bpComplianceSub4") +
            "</h3>",
          type: "q2",
          src: require("@/assets/images/vector/66c80f0b448ee.png"),
          border:false,
          isPopOver:true,
          popOverMessage:"<p>"+this.$t('bpHelpQuestion_38')+"</p>"+"<p>"+this.$t('bpHelpQuestion_39')+"</p>"
        },

        {
          categoryId: 8,
          field: "question_24",
          title:
            '<h2 class="text-white" style="text-align: left;">' +
            this.$t("recomendationBp") +
            "</h3>",
          type: "q3",
          src: require("@/assets/images/vector/66c84523226ba.png"),
          border:false,
        },
        {
          categoryId: 9,
          field: "question_25",
          title:
            '<h2 class="text-white" style="text-align: left;">' +
            this.$t("goodImpressionBp") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/vector/671f591a50ffe.png"),
          border:false,
        },
        {
          categoryId: 10,
          field: "question_26",
          title:
            '<h2 class="text-white" style="text-align: left;">' +
            this.$t("correction") +
            "</h3>",
          type: "q4",
          src: require("@/assets/images/vector/66c8472ff1ab4.png"),
          border:false,
        },
      ];
    },
    preventSubmit() {
      console.log("masuk");
    },
    resultError(data) {
      var count = Object.keys(data).length;
      for (var x = 0; x < count; x++) {
        var nameOb = Object.keys(data)[x];
        var objectData = data[nameOb];
        for (var y = 0; y < objectData.length; y++) {
          this.error(objectData[y]);
        }
      }
    },
    error(kata) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: kata,
      });
    },
    langChanged(lang) {
      this.locale = lang;
      this.$i18n.locale = this.locale;
      localStorage.Lang = this.locale;
      if (this.forms.type) {
        this.loadQuestion(this.$route.params.type);
      }
    },
  },
  events: {},
  created: function () {},
  mounted() {
    let scripts = document.createElement("script");
    scripts.setAttribute("src", "/main_asset/v1/script.js");
    document.head.appendChild(scripts);
    this.loadData();
    this.langChanged(this.$i18n.locale);
  },
  updated() {
    setTimeout(()=> {
      var questions =this.$refs.questions;
      //  active panel - 1 karena activePanel dimulai dari 1
      var questionData = questions[this.activePanel-1];
      this.componentHeight = questionData.offsetHeight+"px";
    },200);
  },

  onLoad(){
    var questions =this.$refs.questions;
      //  active panel - 1 karena activePanel dimulai dari 1
    var questionData = questions[this.activePanel];
    this.componentHeight = questionData.offsetHeight+"px";
  }
};
</script>

<style scoped src="@/assets/v1/css2.css"></style>
<style scoped src="@/assets/v1/style.css"></style>
<style scoped>
  @media (min-width:1024px){
    .floating-right-top{
      position: fixed;
      top     : 0px;
      right   : 0px;
      width   : 150px;  /* Adjust the size as needed */
      z-index : 1000;   /* Ensure it's on top of other elements */
    }
    .floating-left-bottom{
      position: fixed;
      bottom     : 5px;
      left   : 5px;
      width   : 350px;  /* Adjust the size as needed */
      z-index : 1000;   /* Ensure it's on top of other elements */
    }
  }
  @media (max-width: 1023px) {
    .floating-right-top{
      display: none;
    }
    .floating-left-bottom{
      display: none;
    }
  }
  
  @supports (-webkit-text-stroke: 1px white) {
  .appTitle {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: white;
  }
  h3 {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: white;
  }
  h1 {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: white;
  }
  h2 {
    -webkit-text-stroke: 1px black;
    -webkit-text-fill-color: white;
  }
}
</style>