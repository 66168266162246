import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {onRandom,onBehind} from "@/middleware/hadish";
import Axios from 'axios';
import settings from '@/middleware/app';
import VueSweetalert2 from 'vue-sweetalert2';
import messages from "@/middleware/i18n";
import VueI18n from "vue-i18n";

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.prototype.$onRandom         = onRandom;
Vue.prototype.$onBehind         = onBehind;
Vue.prototype.$http             = Axios;
Vue.prototype.$settings         = settings;

Vue.use(VueSweetalert2);
Vue.use(VueI18n);

let lang    = localStorage.Lang;

if (localStorage.getItem("Lang") === null) {
  localStorage.Lang   = "id";
  localStorage.setItem('Lang', "id")
  lang           = "id";
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'id',
  localeDir: 'locales',
  enableInSFC: true,
  messages,
});


new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
