<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  
export default {
  name: 'App',
  components: {
    
  },
  props:{ 
  },
  data () {
  
    return {
      
    }
  },
  computed: {
    
  },
  watch: { 

  },
  methods: {
    

    },
    events: {

    },
    created: function() { 
 
    },
}

</script>